import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import JwtService from "@/utils/jwt.service";

import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () => import("@/views/pages/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/pages/Home/Home.vue"),
      },
      {
        path: "/analytics",
        component: () => import("@/views/pages/Home/Analytics/Index.vue"),
        children: [
          {
            path: "",
            name: "Gerocare App Analytics",
            component: () =>
              import("@/views/pages/Home/Analytics/Gerocare.vue"),
          },
          {
            path: "medical-requests",
            name: "Medical Requests Analytics",
            component: () =>
              import("@/views/pages/Home/Analytics/MedicalRequests.vue"),
          },
          {
            path: "incidents",
            name: "Incidents Analytics",
            component: () =>
              import("@/views/pages/Home/Analytics/Incidents.vue"),
          },
          {
            path: "visits",
            name: "Visits Analytics",
            component: () => import("@/views/pages/Home/Analytics/Visits.vue"),
          },
          {
            path: "elderly-calls",
            name: "Elderly Calls Analytics",
            component: () =>
              import("@/views/pages/Home/Analytics/ElderlyCalls.vue"),
          },
          {
            path: "revenue-expenses",
            name: "Revenue And Expenses",
            component: () =>
              import("@/views/pages/Home/Analytics/RevenueExpenses.vue"),
            beforeEnter: (to, from, next) => {
              const role = store.state.user.role;
              // protecting the user accounts page from other users
              if (role == "management" || role == "admin" || role == "tech") {
                next();
              } else {
                router.go(-1);
              }
            },
          },
        ],
      },

      // calls and followups
      {
        path: "/calls/payments",
        name: "Calls & Follow up / Payments",
        component: () => import("@/views/pages/Calls&Followup/Payments.vue"),
      },
      {
        path: "/calls/outstandingpayments",
        name: "Calls & Follow up / Outstanding Payments",
        component: () =>
          import("@/views/pages/Calls&Followup/OutstandingPayments.vue"),
      },
      {
        path: "/calls/prospect/contacts",
        name: "Prospects / Contact List",
        component: () =>
          import("@/views/pages/Calls&Followup/Prospects/Contacts.vue"),
      },
      {
        path: "/calls/prospect/calls",
        name: "Prospects / Call Logs",
        component: () =>
          import("@/views/pages/Calls&Followup/Prospects/Calls.vue"),
      },
      {
        path: "/calls/addelderly",
        name: "Add Elderly Calls",
        component: () =>
          import("@/views/pages/Calls&Followup/AddElderly/Calls.vue"),
      },
      {
        path: "/calls/expiredsub",
        name: "Expired Subscription Calls",
        component: () =>
          import("@/views/pages/Calls&Followup/ExpiredSub/All.vue"),
      },
      {
        path: "/calls/expiredsub/:id",
        name: "Expired Subscription Calls Records",
        props: true,
        component: () =>
          import("@/views/pages/Calls&Followup/ExpiredSub/Records.vue"),
      },
      {
        path: "/calls/elderly",
        name: "Elderly Call Logs",
        component: () =>
          import("@/views/pages/Calls&Followup/ElderlyCalls.vue"),
      },
      {
        path: "/calls/onboarding-sponsors",
        name: "Onboarding Sponsors",
        component: () =>
          import(
            "@/views/pages/Calls&Followup/Onboarding/OnboardingSponsors.vue"
          ),
      },
      {
        path: "/caregiver-reports",
        name: "Caregiver Reports",
        component: () =>
          import("@/views/pages/Calls&Followup/CaregiverReports.vue"),
      },

      {
        path: "/calls/onboarding-sponsor/:id",
        name: "Onboarding Sponsor",
        component: () =>
          import(
            "@/views/pages/Calls&Followup/Onboarding/OnboardingSponsor.vue"
          ),
      },

      {
        path: "/visit-tracker",
        name: "Visit Tracker",
        component: () => import("@/views/pages/VisitTracker/Index.vue"),
      },
      {
        path: "/visit-tracker/:patientId",
        component: () => import("@/views/pages/VisitTracker/Single/Index.vue"),
        props: true,
        children: [
          {
            path: "",
            name: "Visit Tracker: General",
            component: () =>
              import("@/views/pages/VisitTracker/Single/General.vue"),
            props: true,
          },
          {
            path: "appointments",
            name: "Visit Tracker: Appointments",
            component: () =>
              import("@/views/pages/VisitTracker/Single/Appointments.vue"),
            props: true,
          },
        ],
      },

      // visit reports
      {
        path: "/visitreport/all",
        name: "All Visit Reports",
        component: () => import("@/views/pages/VisitReports/AllReports.vue"),
      },

      // exemplary services
      {
        path: "/exemplary-services/all",
        name: "Exemplary services",
        component: () => import("@/views/pages/ExemplaryService/index.vue"),
      },

      // drugs and prescriptions/medical requests
      {
        path: "/medicalrequests/all",
        name: "All Medical Requests",
        component: () => import("@/views/pages/MedicalRequest/allRequests.vue"),
      },
      {
        path: "/medicalrequests/new",
        name: "New Medical Request",
        component: () => import("@/views/pages/MedicalRequest/NewRequest.vue"),
      },
      {
        path: "/medicalrequests/edit/:id",
        name: "Edit Medical Request",
        component: () => import("@/views/pages/MedicalRequest/EditRequest.vue"),
        props: true,
      },
      {
        path: "/medicalrequests/view/:id?",
        name: "View Medical Request",
        component: () => import("@/views/pages/MedicalRequest/ViewRequest.vue"),
        props: true,
      },

      // Quick Invoices
      {
        path: "/quickinvoice",
        name: "All Quick Invoice",
        component: () => import("@/views/pages/QuickInvoice/All.vue"),
      },
      {
        path: "/quickinvoice/new",
        name: "New Quick Invoice",
        component: () => import("@/views/pages/QuickInvoice/New.vue"),
      },
      {
        path: "/quickinvoice/edit/:id",
        name: "Edit Quick Invoice",
        component: () => import("@/views/pages/QuickInvoice/Edit.vue"),
        props: true,
      },

      // incident reports
      {
        path: "/incidents/all",
        name: "Incident Reports",
        component: () => import("@/views/pages/Incident/AllIncidents.vue"),
      },
      {
        path: "/incidents/create",
        name: "Create Incident Report",
        component: () => import("@/views/pages/Incident/NewIncident.vue"),
      },
      {
        path: "/incidents/view/:id",
        name: "View Incident Report",
        component: () => import("@/views/pages/Incident/ViewIncident.vue"),
        props: true,
      },

      // corportate clients
      {
        path: "/corportate/clients",
        name: "Corporate Clients",
        component: () => import("@/views/pages/Corporate/Clients.vue"),
      },
      {
        path: "/corportate/clients/members/:id",
        name: "Client Members",
        component: () => import("@/views/pages/Corporate/ClientMembers.vue"),
        props: true,
      },

      // Tech logs
      {
        path: "/techlogs",
        name: "Tech Logs",
        component: () => import("@/views/pages/Techlogs/All.vue"),
      },

      // Payout requests
      {
        path: "/payout-request-categories",
        name: "Payout Request Categories",
        component: () =>
          import("@/views/pages/PayoutRequest/PayoutRequestCategory.vue"),
      },

      {
        path: "/payout-requests",
        component: () => import("@/views/pages/PayoutRequest/Index.vue"),
        children: [
          {
            path: "",
            name: "Payout Requests",
            component: () =>
              import("@/views/pages/PayoutRequest/PendingPayoutRequest.vue"),
          },
          {
            path: "/payout-requests/completed",
            name: " Payout Requests",
            component: () =>
              import("@/views/pages/PayoutRequest/CompletedPayoutRequest.vue"),
          },
        ],
      },
      {
        path: "/payout-requests/:reference",
        name: "Payout Request",
        component: () => import("@/views/pages/PayoutRequest/View.vue"),
        props: true,
      },

      // Vendors
      {
        path: "/vendors",
        name: "Vendors",
        component: () => import("@/views/pages/Vendors/Index.vue"),
      },

      // Tasks
      {
        path: "/tasks",
        name: "Weekly Reports",
        component: () => import("@/views/pages/Tasks/Tasks.vue"),
      },
      {
        path: "/tasks/create",
        name: "Create Weekly Report",
        component: () => import("@/views/pages/Tasks/CreateTask.vue"),
      },
      {
        path: "/tasks/grade/:id?",
        name: "Grade Weekly Report",
        component: () => import("@/views/pages/Tasks/GradeTask.vue"),
      },
      {
        path: "/tasks/view/:id",
        name: "View Weekly Report",
        component: () => import("@/views/pages/Tasks/ViewTask.vue"),
      },
      {
        path: "/tasks/edit/:id",
        name: "Edit Weekly Report",
        component: () => import("@/views/pages/Tasks/EditTask.vue"),
      },
      {
        path: "/attendance",
        name: "Attendance",
        component: () => import("@/views/pages/Attendance/Index.vue"),
      },

      // Remarks
      {
        path: "/daily-remarks",
        name: "Daily Remarks",
        component: () => import("@/views/pages/Remarks/index.vue"),
      },
      {
        path: "/investigation-logs",
        name: "Investigation logs",
        component: () => import("@/views/pages/Investigation/index.vue"),
      },

      // Business Development
      {
        path: "/businessdevelopment",
        name: "Business Development",
        component: () => import("@/views/pages/BusinessDev/All.vue"),
      },
      {
        path: "/businessdevelopment/logs/:id",
        name: "Business Development Logs",
        component: () => import("@/views/pages/BusinessDev/ViewLogs.vue"),
      },

      // First Visits
      {
        path: "/firstvisits",
        name: "First Visits Report",
        component: () => import("@/views/pages/FirstVisits/All.vue"),
      },

      // Upcoming Appointments
      {
        path: "/upcoming/appointments",
        name: "Upcoming Appointments Report",
        component: () => import("@/views/pages/Upcoming/Appointments.vue"),
      },

      // Investor reports
      {
        path: "/investor-reports",
        name: "Investor Reports",
        component: () => import("@/views/pages/InvestorReports/Index.vue"),
      },

      // Activity logs
      {
        path: "/activity-logs",
        name: "Activity Logs",
        component: () => import("@/views/pages/ActivityLogs/Index.vue"),
      },

      // settings
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/pages/Settings/Profile.vue"),
      },
      {
        path: "/useraccounts",
        name: "User Accounts",
        component: () => import("@/views/pages/Settings/UserAccounts.vue"),
        beforeEnter: (to, from, next) => {
          const role = store.state.user.role;
          // protecting the user accounts page from other users
          if (role == "management" || role == "admin") {
            next();
          } else {
            router.go(-1);
          }
        },
      },
    ],
  },

  // Auth Routes
  {
    path: "/auth",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
        props: (route) => ({ redirect: route.query.redirect }),
      },
      {
        path: "/reset",
        name: "Reset",
        component: () => import("@/views/auth/Reset.vue"),
      },
      {
        path: "/setpassword/:id",
        name: "SetPassword",
        component: () => import("@/views/auth/SetPassword.vue"),
      },
      {
        path: "/setpasswordsuccess",
        name: "SetPasswordSuccess",
        component: () => import("@/views/auth/SetPasswordSuccess.vue"),
      },
    ],
  },

  // 500 page
  {
    path: "/500",
    component: () => import("@/views/pages/404/500.vue"),
  },

  // 404 page
  {
    path: "/404",
    component: () => import("@/views/pages/404/NotFound.vue"),
  },

  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) { // eslint-disable-line
    return { x: 0, y: 0 };
  },
});

// Authentication Navigation Guard
router.beforeEach((to, from, next) => {
  let publicPages = ["Login", "Reset", "SetPassword", "SetPasswordSuccess"];
  let authRequired = !publicPages.includes(to.name);

  if (authRequired) {
    let token = JwtService.getToken();
    let user = store.getters.user;

    if (!token) {
      next({ path: "/login", replace: true, query: { redirect: to.fullPath } });
      return;
    }

    if (token && !user) {
      http
        .get(endpoints.GET_LOGGED_IN_USER)
        .then(async (response) => {
          await store.dispatch("updateInfo", response);

          next();
          return;
        })
        .catch((error) => {
          if (error === "Unauthenticated") {
            store.dispatch("purge_auth");
          }

          next({
            path: "/login",
            replace: true,
            query: { redirect: to.fullPath },
          });
          return;
        });
    }
  }

  next();
});

// Ganar Users Navigation Guard
router.beforeEach((to, from, next) => {
  const allowedPages = [
    "Weekly Reports",
    "Create Weekly Report",
    "Grade Weekly Report",
    "View Weekly Report",
    "Edit Weekly Report",
    "Profile",
  ];
  if (
    JwtService.getToken() != "" &&
    store.state.user?.role == "ganar" &&
    !allowedPages.includes(to.name)
  ) {
    next(false);
  } else {
    next();
  }
});

export default router;

import Vue from "vue";
import _ from "lodash";

Vue.filter("uppercase", function (value) {
  if (!value) {
    return "";
  }

  return value.toString().toUpperCase();
});

Vue.filter("capitalize", function (value) {
  if (!value) {
    return "";
  }

  return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("startCase", function (value) {
  if (!value) {
    return "";
  }

  return _.startCase(_.toLower(value));
});

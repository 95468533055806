/**
 * Main Application endpoints
 *
 * @return object
 */

export const endpoints = {
  // auth endpoints
  CREATE_USER: "/auth/createUser",
  LOGIN: "/auth/login",
  GET_LOGGED_IN_USER: "/auth/me",
  REFRESH_TOKEN: "/auth/refresh",
  RESET_PASSWORD: "/auth/resetPassword",
  SET_NEW_PASSWORD: "/auth/changePassword",
  LOGOUT: "/auth/logout",

  // settings -> user accounts endpoints (FOr Admin Users only)
  GET_ALL_USERS: "/getAllUsers",
  UPDATE_USER_PROFILE: "updateUser",
  DELETE_USER: "/deleteUser/:id",
  // ->profile
  UPDATE_USER_INFORMATION: "/updateuserinfo",
  UPDATE_USER_PASSWORD: "/updateuserpassword",

  // visit reports endpoints
  GET_ALL_VISIT_REPORTS: "/visitreports/all",
  UPDATE_VISIT_REPORT: "/visitreports/update",
  FECTH_VISIT_REPORTS_FOR_CORPORATE_CLIENT: "/visitreports/corporate",
  FETCH_UNCOMPLETE_VISIT_CYCLES: "visitreports/uncompleted/patient/:id",

  FETCH_CORPORATE_CLIENT_PATIENT_REMARKS: "/corporateclientpatientremark/fetch",
  SAVE_CORPORATE_CLIENT_PATIENT_REMARKS: "/corporateclientpatientremark/remark",

  // Drugs and Prescriptions (Medical Requests)
  GET_ALL_PRESCRIPTION_RECORDS: "/prescriptions/all",
  ADD_PRESCRIPTION_RECORD: "/prescriptions/create",
  GET_SINGLE_PRESCRIPTION_RECORD: "/prescriptions/:id",
  UPDATE_PRESCRIPTION_RECORD: "/prescriptions/:id",
  RESEND_PRESCRIPTION_INVOICE: "/prescriptions/resendinvoice/:id",
  UNPAID_RECORDS_FOR_ELDERLY: "/prescriptions/elderly/unpaid/:id",
  DELETE_PRESCRIPTION_INVOICE: "/prescriptions/:id",
  SPLIT_PRESCRIPTION_INVOICE: "/prescriptions/:id/split",
  UPDATE_PRESCRIPTION_PAY_STATUS: "/prescriptions/:id/pay-status",
  UPDATE_PRESCRIPTION_DELIVERY_STATUS: "/prescriptions/:id/delivery-status",
  UPDATE_INVOICE_ITEM_PRESCRIPTION_DELIVERY_STATUS:
    "/prescriptions/:id/update-invoice-item-delivery-status",
  PAY_PRESCRIPTION_VENDORS: "/prescriptions/:id/pay-vendors",
  PAY_INVOICE_ITEM_VENDOR: "/prescriptions/:id/pay-invoice-item-vendor",
  REPLICATE_PRESCRIPTION: "/prescriptions/:id/replicate",
  PAY_PRESCRIPTION_FROM_WALLET: "/prescriptions/:id/pay-from-wallet",
  YESTERDAY_PRESCRIPTIONS: "/prescriptions/created-yesterday",

  // Calls & Followup -> Payments
  ALL_CALLS_AND_FOLLOWUP: "/calls",
  NEW_CALL_AND_FOLLOW_UP: "/calls",
  EDIT_CALL_AND_FOLLOWUP: "/calls/:id",
  DELETE_CALL_AND_FOLLOWUP: "/calls/:id",

  // Calls & Followup -> Outstanding Payments
  NEW_OUTSTANDING_CALL_AND_FOLLOW_UP: "/outstanding/calls/new",
  EDIT_OUTSTANDING_CALL_AND_FOLLOWUP: "/outstanding/calls/edit",
  DELETE_OUTSTANDING_CALL_AND_FOLLOWUP: "/outstanding/calls/delete/:id",
  ALL_OUTSTANDING_CALLS_AND_FOLLOWUP: "/outstanding/calls/all",

  // Calls & Followup -> Prospects
  ALL_PROSPECTS: "/prospect/all",
  NEW_PROSPECT: "/prospect/new",
  EDIT_PROSPECT: "/prospect/update",
  DELETE_PROSPECT: "/prospect/delete/:id",
  ALL_PROSPECTS_ONLY: "/prospect/only/all",
  ALL_PROSPECT_CALLS: "/prospect/calls/all",
  NEW_PROSPECT_CALL: "/prospect/calls/new",
  DELETE_PROSPECT_CALL: "/prospect/calls/delete/:id",

  // Calls & Followup -> add Elderly
  ALL_ADD_ELDERLY_CALLS: "/addelderly/calls/all",
  NEW_ADD_ELDERLY_CALLS: "/addelderly/calls/new",
  DELETE_ADD_ELDERLY_CALL: "/addelderly/calls/delete/:id",

  // Calls & Followup -> expired sub
  ALL_EXPIRED_SUB_CALLS: "/expiredsub/calls/all",
  NEW_EXPIRED_SUB_CALLS: "/expiredsub/calls/new",
  DELETE_EXPIRED_SUB_CALL: "/expiredsub/calls/delete/:id",

  // Incident Reports
  GET_ALL_INCIDENTS: "/incidents",
  CREATE_INCIDENT: "/incidents",
  FETCH_SINGLE_INCIDENT: "/incidents/:id",
  CREATE_INCIDENT_ACTION: "/incidents/:id/actions",

  // Tech Logs
  FETCH_TECH_LOGS: "/techlogs/all",
  NEW_TECH_LOG: "/techlogs/new",
  UPDATE_TECH_LOG: "/techlogs/update",
  DELETE_TECH_LOG: "/techlogs/delete/:id",

  // Dashboard
  DASHBOARD_DATA: "/dashboard/data",

  GET_PAYSTACK_BALANCE: "/paystack/balance",

  // Calls & Followup -> Elderly Calls
  ALL_ELDERLY_CALLS: "/elderly/calls",
  NEW_ELDERLY_CALLS: "/elderly/calls",
  FETCH_ELDERLY_CALL: "/elderly/calls/:id",
  DELETE_ELDERLY_CALL: "/elderly/calls/:id",
  FETCH_ELDERLY_CALL_RATING_QUESTIONS: "/elderly-call-rating-questions",

  // Leads/Business Development
  ALL_LEADS: "/businessdev/all",
  NEW_LEAD: "/businessdev/new",
  EDIT_LEAD: "/businessdev/update",
  DELETE_LEAD: "/businessdev/delete/:id",
  FETCH_SINGLE_LEAD: "businessdev/single/:id",
  NEW_LEAD_UPDATE: "businessdev/lead/update",
  GET_LOGS_FOR_SINGLE_LEAD: "businessdev/logs/:id",

  // Tasks
  ADD_TASK: "/tasks/create",
  FETCH_TASKS: "/tasks/fetch",
  SINGLE_TASK: "/tasks/single/:id",
  GRADE_TASK: "/tasks/grade",
  LAST_TASK: "/tasks/last",
  USER_LAST_TASK: "/tasks/user/last",
  UPDATE_TASK: "/tasks/update",
  CALCULATE_TASK_POINTS: "/tasks/calculate",
  SAVE_NEW_TASK_FEEDBACK: "/tasks/feedback",
  GENERATE_REPORT_PDF: "/tasks/pdf/:id",
  GENERATE_ENTIRE_WEEK_PDF: "/tasks/weekly/pdf",

  // Quick Invoice
  QUICK_INVOICE: "/quickinvoices",
  ALL_QUICK_INVOICES: "/quickinvoices",
  FETCH_SINGLE_QUICK_INVOICE: "/quickinvoices/:id",
  UPDATE_QUICK_INVOICE: "/quickinvoices/:id",
  DELETE_QUICK_INVOICE: "/quickinvoices/delete/:id",

  // Notifications
  NOTIFICATIONS: "/notifications",

  // First Visits
  NEW_FIRST_VISITS: "/firstvisits/new",
  UPDATE_FIRST_VISITS: "/firstvisits/update",

  // Expired Subscribers
  FETCH_EXPIRED_SUB_REMARKS: "/expired/fetch",
  NEW_EXPIRED_SUB_REMARK: "/expired/remark",
  STORE_EXPIRED_SUB_REMINDER: "/expired/reminder",
  EXPIRED_SUB_REMINDER: "/expired/reminders",

  // Upcoming appointments feedback
  FETCH_UPCOMING_APPOINTMENT_FEEDBACK: "/upcomingvisits/fetch",
  NEW_UPCOMING_APPOINTMENT_FEEDBACK: "/upcomingvisits/remark",

  // Investors
  FETCH_ALL_INVESTORS: "/investors/fetch",
  ADD_NEW_INVESTOR: "/investors/create",
  UPDATE_INVESTOR: "/investors/edit",
  DELETE_INVESTOR: "/investors/delete/:id",

  // Activity logs
  FETCH_ACTIVITY_LOGS: "/activity-logs",

  // Payout requests
  CREATE_PAYOUT_REQUEST: "/payout-requests",
  VIEW_ALL_PAYOUT_REQUESTS: "/payout-requests",
  VIEW_PAYOUT_REQUEST: "/payout-requests/:reference",
  UPDATE_PAYOUT_REQUEST: "/payout-requests/:id",
  DELETE_PAYOUT_REQUEST: "/payout-requests/:id",
  EXPORT_PAYOUT_REQUESTS: "/payout-requests/export",

  // Payout request categories
  VIEW_ALL_PAYOUT_REQUEST_CATEGORIES: "/payout-request-categories",
  CREATE_PAYOUT_REQUEST_CATEGORY: "/payout-request-categories",
  UPDATE_PAYOUT_REQUEST_CATEGORY: "/payout-request-categories/:id",
  DELETE_PAYOUT_REQUEST_CATEGORY: "/payout-request-categories/:id",
  RESTORE_PAYOUT_REQUEST_CATEGORY: "/payout-request-categories/:id/restore",

  // Payout request sub-categories
  VIEW_ALL_PAYOUT_REQUEST_SUB_CATEGORIES: "/payout-request-sub-categories",
  CREATE_PAYOUT_REQUEST_SUB_CATEGORY: "/payout-request-sub-categories",
  UPDATE_PAYOUT_REQUEST_SUB_CATEGORY: "/payout-request-sub-categories/:id",
  DELETE_PAYOUT_REQUEST_SUB_CATEGORY: "/payout-request-sub-categories/:id",
  RESTORE_PAYOUT_REQUEST_SUB_CATEGORY:
    "/payout-request-sub-categories/:id/restore",

  // Vendors
  GET_VENDOR_SUM_BALANCES: "/vendors/balances",
  EXPORT_VENDOR_PAYMENT_TRANSACTIONS: "/vendors/export-payment-transactions",
  CREATE_VENDOR: "/vendors",
  VIEW_ALL_VENDORS: "/vendors",
  VIEW_VENDOR: "/vendors/:id",
  VIEW_ALL_VENDOR_WALLET_TRANSACTIONS: "/vendors/:id/wallet-transactions",
  UPDATE_VENDOR: "/vendors/:id",
  SUSPEND_VENDOR: "/vendors/:id/toggle-suspension",
  HOLD_VENDOR_PAYMENT: "/vendors/:id/toggle-payment-holding-status",
  UPDATE_VENDOR_BANK_ACCOUNT: "/vendors/:id/update-bank-account",
  TRIGGER_VENDOR_PAYMENT: "/vendors/:id/trigger-payment",
  UPDATE_VENDOR_WALLET: "/vendors/:id/update-wallet",

  // Banks & Bank Account
  GET_ALL_BANKS: "/banks",
  VIEW_BANK_ACCOUNT: "/bank-account",
  UPDATE_BANK_ACCOUNT: "/bank-account",

  // Analytics
  GET_MEDICAL_REQUESTS_ANALYTICS: "/analysis/prescriptions",
  GET_INCIDENTS_ANALYTICS: "/analysis/incidents",
  GET_VISITS_ANALYTICS: "/analysis/visits",
  GET_ELDERLY_CALLS_ANALYTICS: "/analysis/elderly-calls",
  GET_REVENUE_EXPENSES_ANALYTICS: "/analysis/revenue-expenses",

  // Appointments
  FETCH_APPOINTMENT_REMARKS: "/appointments/:id/remarks",
  SAVE_APPOINTMENT_REMARK: "/appointments/:id/remarks",

  // Attendance
  FETCH_ATTENDANCES: "/attendances",
  CREATE_ATTENDANCE: "/attendances",
  FETCH_ATTENDANCE: "/attendances/:attendanceId",
  DELETE_ATTENDANCE_TASK: "/attendances/:attendanceId/tasks/:taskId",
  COMPLETE_ATTENDANCE_TASK: "/attendances/:attendanceId/tasks/:taskId/complete",
  SORT_ATTENDANCE_TASK: "/attendances/:attendanceId/tasks/sort",
  STORE_ATTENDANCE_FEEDBACK: "/attendances/:attendanceId/feedbacks",
  FETCH_USER_ATTENDANCE_TASKS: "/attendances/users/:userId",
  ROLLOVER_PREVIOUS_DAY: "/attendances/rollover/previous-day",

  // Search users
  SEARCH_USERS: "/users/search",

  // Task comments
  CREATE_TASK_COMMENT: "/attendances/:taskId/comment/create",
  DELETE_TASK_COMMENT: "/attendances/:taskId/comment/delete/:commentId",
  DELETE_TASK_COMMENT_ATTACHMENT:
    "/attendances/:taskId/comment/delete/attachment/:attachmentId",

  // Daily Remark
  SAVE_DAILY_REMARK: "/daily-remarks/store",
  DELETE_DAILY_REMARK: "/daily-remarks/:id/delete",
  DAILY_REMARKS: "/daily-remarks",

  // Caregiver Reports
  SAVE_CAREGIVER_REPORT: "/caregiver-reports/store",
  DELETE_CAREGIVER_REPORT: "/caregiver-reports/:id/delete",
  CAREGIVER_REPORTS: "/caregiver-reports",
  CAREGIVER_SHOW: "/caregiver-reports/:id/show",

  // Investigation Log
  INVESTIGATION_LOGS: "/investigation-logs",
  INVESTIGATION_LOGS_STORE: "/investigation-logs/store",
};

/**
 * Gerocare App Endpoints
 *
 * @return object
 */
const key = process.env.VUE_APP_API_SECRET_KEY;

export const geroendpoints = {
  FETCH_ALL_DOCTORS: `/allDoctors/${key}`,
  FETCH_SPONSORS_WITHOUT_ELDERLY: `/sponsor/patients/none/${key}`,
  FETCH_SPONSORS_WITH_EXPIRED_SUB: `/sponsor/sub/expired/${key}`,
  FETCH_CORPORATE_CLIENTS: `/corporate/clients/${key}`,
  FETCH_CORPORATE_CLIENT_DATA: `/corporate/client/:id/${key}`,
  DASHBOARD_DATA: `/dashboard/data/${key}`,
  ANALYTCIS_DATA: `/dashboard/analytics/${key}`,

  FETCH_ALL_PATIENTS: `/patients/old`,
  FIRST_VISITS: "/firstvisits",
  ALL_EXPIRED_SUB: `/expiredsubs`,
  SEND_APPOINTMENT_REMINDER_FOR_CORPORATE_CLIENT: `/corporate/visitreminder`,
  FETCH_UPCOMING_VISITS: `/upcoming`,
  FETCH_UPCOMING_VISITS_THIS_WEEK_AND_NEXT: `/upcomingthisweekandnext`,
  HIDE_FIRST_VISIT: `/firstvisits/hide`,
  RENEWED_FIRST_VISITS: "/firstvisits/renewed",
  FETCH_PATIENTS: `/patients`,
  FETCH_PATIENT: `/patients/:id`,
  FETCH_PATIENTS_APPOINTMENTS_FOR_RATING:
    "/patients/:patientId/appointments/ratings",

  // Calls & follow up onboarding sponsors
  FETCH_ONBOARDING_SPONSORS: "/onboarding-sponsors",
  FETCH_ONBOARDING_SPONSOR: "/onboarding-sponsors/:id",
  SAVE_ONBOARDING_SPONSOR_LOG: "/onboarding-sponsors/:id/logs",
  TOGGLE_ONBOARDING_SPONSOR: "/onboarding-sponsors/:id/toggle",
  SAVE_ONBOARDING_REMINDER: "/onboarding-sponsors/:id/reminder",

  // APPOINTMENTS
  FETCH_APPOINTMENTS: "/appointments",
  FETCH_APPOINTMENT: "/appointments/:id",

  // VISIT TRACKER
  FETCH_VISIT_TRACKER: `/visit-tracker`,

  //APP REVENUE & EXPENSES
  FETCH_APP_REVENUE_EXPENSES: `/revenue-expenses`,

  //MEDSERVICES DOCTOR REVIEW
  SAVE_EXEMPLARY_SERVICE: `/exemplary-services/:report/store`,
  FETCH_EXEMPLARY_SERVICE: `/exemplary-services`,

  //Daily Log
  DAILY_LOG: "/doctors/daily-logs",
};

var moment = require("moment");
export default {
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },

    formatDateTime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },

    formatFancyDate(date) {
      return moment(date).format("dddd, MMM Do YYYY");
    },

    formatFancyDateFull(date) {
      return moment(date).format("dddd, MMM Do YYYY, hh:mm:ss a");
    },

    formatFancyDateAgo(date) {
      return moment(date).fromNow();
    },
  },
};

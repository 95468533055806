import axios from "axios";
import store from "../store/index.js";
import JwtService from "./jwt.service";
import router from "@/router";

/**
 * here we have the base url for all http request
 * that will be made to the REST API
 */

export const url = `${store.getters.url}/api`;

/**
 * default headers for http request
 */
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

/**
 * Create new axios instance
 *
 * @return void
 */
const instance = axios.create({
  baseURL: url,
});

/**
 * Add a response interceptor
 *
 * @return void
 */
instance.interceptors.response.use(
  // handle success
  function (response) {
    return response.data;
  },

  // handle errors
  function (error) {
    if (error.message == "Network Error") {
      return Promise.reject(error.message);
    }

    if (error.response.status == 401) {
      router.replace({
        path: "/login",
        replace: true,
        query: { redirect: router.currentRoute.fullPath },
      });

      store.dispatch("purge_auth");

      return Promise.reject("Unauthenticated");
    }

    // if (error.response.status == 500) {
    //   return window.location.replace("/500");
    // }

    if (error.response.data instanceof Array) {
      return Promise.reject(error.response.data[0]);
    }

    return Promise.reject(error);
  }
);

/**
 * Add a Request Interceptor
 *
 * @return void
 */
instance.interceptors.request.use(
  async (config) => {
    try {
      let token = JwtService.getToken();

      // add default headers
      config.headers = Object.assign({}, config.headers, headers);

      // check if a token was found
      if (token) {
        // add user token to request header
        config.headers = Object.assign({}, config.headers, {
          Authorization: `Bearer ${token}`,
        });
      }

      // return transformed config
      return config;
    } catch (error) {
      // reject error if failed
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * export axios instance as http
 */
export const http = instance;

export default {
  methods: {
    formatMoney(amount, currency = 'NGN') {
      return new Intl.NumberFormat(currency, {
        style: "currency",
        currency: currency,
      }).format(amount);
    },

    formattedAmount(currency, amount) {
      var newCurrency = currency ? currency : "NGN";
      return new Intl.NumberFormat(newCurrency, {
        style: "currency",
        currency: newCurrency,
      }).format(amount);
    },
  },
};

import Vue from "vue";
import { EventBus } from "@/utils/event-bus";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => {
      return createElement(
        "span",
        {
          on: {
            click: () => {
              EventBus.$emit("vselect-deselect", this);
            },
          },
        },
        "❌"
      );
    },
  },
});
Vue.component("v-select", vSelect);

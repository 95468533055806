<template>
  <div id="app">
    <vue-page-transition name="fade-in-right">
      <router-view></router-view>
    </vue-page-transition>

    <b-toast
      id="my-toast"
      variant="success"
      solid
      auto-hide-delay="60000"
      :visible="updateExists"
      no-auto-hide
    >
      <template #toast-title>
        <div class="w-100 text-center">
          <strong class="mx-auto">New Update to CRM Available!</strong>
        </div>
      </template>
      <button class="btn btn-success btn-block" @click.prevent="refreshApp">
        Get Update <i class="fa fa-download"></i>
      </button>
    </b-toast>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },

  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },

  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },

    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style>
.v-toast__text {
  color: white !important;
}
</style>

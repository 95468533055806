import Vue from "vue";
import Vuex from "vuex";
import JwtService from "@/utils/jwt.service";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    url: process.env.VUE_APP_CRM_URL,
    gcurl: process.env.VUE_APP_GEROCARE_URL,
    michurl: process.env.VUE_APP_MICH_URL,
    user: null,
  },

  mutations: {
    authenticate(state, payload) {
      state.user = payload.user;

      if (payload.access_token) {
        JwtService.saveToken(payload.access_token);
      }
    },
    purge_auth(state) {
      state.user = null;
      JwtService.destroyToken();
    },
    updateUserInfo(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    authenticate(context, payload) {
      context.commit("authenticate", payload);
    },

    purge_auth(context) {
      context.commit("purge_auth");
    },

    updateInfo(context, payload) {
      return new Promise((resolve) => {
        context.commit("updateUserInfo", payload);

        setTimeout(resolve, 500);
      });
    },
  },

  getters: {
    url: (state) => {
      return state.url;
    },
    gcurl: (state) => {
      return state.gcurl;
    },
    michurl: (state) => {
      return state.michurl;
    },
    user: (state) => {
      return state.user;
    },
  },
});

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

window.Popper = require("popper.js").default;
window.$ = window.jQuery = require("jquery");

require("bootstrap");
window._ = require("lodash");
require("jquery-ui");

import "./registerServiceWorker";

// Plugins
import "@/plugins/vform";
import "@/plugins/vue-filter-pretty-bytes";
import "@/plugins/bootstrap-vue";
import "@/plugins/vue-page-transition";
import "@/plugins/sentry";
import "@/plugins/v-money";
import "@/plugins/v-tooltip";
import "@/plugins/v-viewer";
import "@/plugins/vue-apexcharts";
import "@/plugins/vue-flatpickr";
import "@/plugins/vue-select";
import "@/plugins/vue-toast-notification";
import "@/plugins/vue2-perfect-scrollbar";
import "@/plugins/vue-moment";

import "@/filters";
import "@/prototypes/dates";

// Styles
import "@/assets/css/forms/theme-checkbox-radio.css";

Vue.config.productionTip = false;

// Mixins go here
import DateMixin from "./mixins/dates";
import MoneyMixin from "./mixins/money";
Vue.mixin(MoneyMixin);
Vue.mixin(DateMixin);

//Initialize Vue Instance
new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      userRoles: [
        "accounts",
        "admin",
        "client services",
        "digital marketing",
        "elderly services",
        "ganar",
        "management",
        "medical services",
        "tech",
      ],
    };
  },
  methods: {
    pagination(route, goToPage) {
      if (route.query.page) {
        goToPage(route.query.page);
      } else {
        goToPage(1);
      }
    },
  },
}).$mount("#app");
